import Vue from "vue";
import Vuex  from "vuex";
import axios from "axios";``
import Constants from '../constants';
// import VueResource from 'vue-resource';

// Vue.use(VueResource);
Vue.use(Vuex);

// const hostname=window.location.hostname;
//const apiURL=location.protocol+"//"+hostname+":3000";
// const apiBaseURL=location.protocol+"//"+hostname+":8080/api";
const apiBaseURL="/api";

export default new Vuex.Store({
  state: {
    infoMessages:undefined,
    loggedin:undefined,
    user:undefined,
    isChef:undefined,
    isAdmin:undefined,
    snackbar: {
      color: "",
      visible: false,
      text: null,
      timeout: 6000,
      multiline: false,
      bottom:true
    },
    chefs: [],
    languages:undefined,
    serviceTypes:undefined,
    dishTypes:undefined,
    dietaryTags:undefined,
    styleTags:undefined,
    documentTypes:undefined,
    workingUser:undefined,
    chefsAdmin:undefined,
    clientsAdmin:undefined,
    bookingsAdmin:undefined,
    chefsSearch:undefined,
    booking:{},
    potentialCustomers: { content: [], totalElements: 0 }, // Initialize potentialCustomers
  },
  getters: {
    getUser: state => state.user,
    // getServiceTypes: state => state.serviceTypes,
    chefs: state => state.chefs.map(s => ({
      ...s,
      // fullName: s.firstName + ' ' + s.lastName
    })),
    findChef: state => id => state.chefs.find(s => s.id == id),
    isLoaded: state => !!state.chefs.length,
    // chefFiltered:state => {
    //   // var hey=this.formData.courses;
    //   // console.log(hey);
    //   var result = state.chefs;
    //   var chef;
    //   for (chef of state.chefs) {
    //     console.log(chef);
    //     chef.from = this.findFromPrice(chef);
    //   }
    //   return result;
    // },
    // findFromPrice :state => id =>  {
    //
    //   var chef= state.chefs.find(s => s.id == id)
    //   var cheapestStarter = chef.starter.sort(function(a, b) {
    //     return a.price - b.price;
    //   });
    //   var cheapestMain = chef.main.sort(function(a, b) {
    //     return a.price - b.price;
    //   });
    //   return cheapestStarter[0].price + cheapestMain[0].price;
    // }
    // loggedin: state => state.loggedin,
  },
  mutations: {
    showSnackbar(state, payload) {
      console.log(payload);
      state.snackbar.color = payload.color
      state.snackbar.text = payload.text
      state.snackbar.multiline = (payload.text && payload.text.length > 50) ? true : false
      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline
      }
      if (payload.timeout!==undefined) {
        state.snackbar.timeout = payload.timeout
        console.log(state.snackbar.timeout);
      }else{
        delete state.snackbar.timeout;
      }
      state.snackbar.visible = true;
      console.log(state.snackbar);
    },
    closeSnackbar(state) {
      state.snackbar.visible = false
      state.snackbar.multiline = false
      state.snackbar.timeout = 6000
      state.snackbar.text = null
    },
    setChefs(state, chefs) {
      state.chefs = chefs;
    },
    addChef(state, chef) {
      state.chefs.push(chef);
    },
    editChef(state, chef) {
      const index = state.chefs.findIndex(s => s.id == chef.id);
      Vue.set(state.chefs, index, chef);
    },
    setUser(state, user){
      state.user=user;
    },
    // setServiceTypes(state, serviceTypes){
    //   state.serviceTypes=serviceTypes;
    //   Vue.set(state.serviceTypes, serviceTypes);
    // }
  },
  actions: {
    async getEnums(){
      const enums = (await axios.get(apiBaseURL+'/util/enums')).data;
      Object.entries(enums).forEach(e=>{
        Vue.set(this.state,e[0],e[1]);
      });
    },
    async sendSMS({ dispatch }, data) {
      try {
        (await axios.post(apiBaseURL + '/admin/send-sms', data)).data;
      } catch (e) {
        dispatch('handleResponse', e.response);
      }
    },
    async getParameter({dispatch},name){
      let state=this.state;
      try{
        const value = (await axios.get(apiBaseURL+'/util/parameter/'+name)).data;
        Vue.set(state,name,value);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
    },
    async getParameters({dispatch}){
      let state=this.state;
      try{
        const value = (await axios.get(apiBaseURL+'/util/parameters')).data;
        Vue.set(state,"parameters",value);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
    },
    async saveParameter({dispatch}, data){
      let state=this.state;
      try{
        const response = (await axios.put(apiBaseURL+'/admin/parameter/'+data.name+'/'+data.value)).data;
        Vue.set(state,data.name,response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
    },
    async saveParameters({dispatch},payload){
      let state=this.state;
      try{
        const response = (await axios.put(apiBaseURL+'/admin/parameters',{parameters:payload})).data;
        Vue.set(state,"parameters",response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
    },
    async getLanguages(){
        const languages = (await axios.get(apiBaseURL+'/util/languages')).data;
        console.log(languages);
        this.state.languages=languages;
    },
    async getDocumentTypes(){
      const types = (await axios.get(apiBaseURL+'/util/document/types')).data;
      console.log(types);
      // context.commit('setDocumentTypes', types);
      this.state.documentTypes=types;
    },

    async getServiceTypes(){
      let types = (await axios.get(apiBaseURL+'/util/service/types')).data;
      console.log(types);
      types=types.filter(function (el) {
        // Hiding CLass for now
        return el.name !== "CLASS"
      });
      // context.commit("setServiceTypes", types)
      this.state.serviceTypes=types;
    },
    async getSubjects(){
      const result = (await axios.get(apiBaseURL+'/util/subjects')).data;
      console.log(result);
      this.state.subjects=result;
    },
    showSnackbar (context, payload){
      context.commit('showSnackbar', payload);
    },
    handleResponse (context, response){
      if (response!==undefined && response.data !==undefined && response.data.statusCode!==200){
        let payload={
          text: Constants.ERROR,
          color: "error"
        };
        if (response.data !==undefined && response.data.errorMessage!==undefined){
          payload.text=response.data.errorMessage;
        }
        if (response.data !==undefined && response.data.validationMessages !== null) {
          let messages = Object.entries(response.data.validationMessages);
          payload.text=Constants.SAVED+"\n"+messages.join("\n");
        }
        context.commit('showSnackbar', payload);
      }
    },
    async saveUser({/*commit,*/ dispatch}, user) {
      console.log(this.state.user);
      console.log(user);
      let state=this.state;
      let response;
      try{
        user.userAgent=navigator.userAgent;
        response = (await axios.put(apiBaseURL+'/user', user)).data;
        console.log(response);
        if (state.isAdmin){
          state.workingUser=response.result;
          state.workingUser.validationMessages=response.validationMessages;
        }
        if ( user.id===state.user.id){
          state.user=response.result;
          state.user.validationMessages=response.validationMessages;
        }

      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
        // throw e.response
      }
      return response;
    },
    async reviewDone({/*commit,*/ dispatch}, user) {
      console.log(this.state.user);
      let state=this.state;
      let response;
      try{
        response = (await axios.put(apiBaseURL+'/user/reviewed', user)).data;
        console.log(response);
        if (state.isAdmin && user.id!==state.user.id ){
          state.workingUser=response.result;
          state.workingUser.validationMessages=response.validationMessages;
        }else{
          state.user=response.result;
          state.user.validationMessages=response.validationMessages;
        }

        // this.state.user=response.result;
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
        // throw e.response
      }
      return response;
    },
    async uploadProfilePhoto({ dispatch}, data) {
      let response;
      let state=this.state;
      try{
        let formData = new FormData();
        formData.append('photo', data.photo);
        formData.append('userId', data.user.id);
        response = (await axios.post(apiBaseURL+'/user/profile/photo', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })).data;
        console.log(response);
        if (state.isAdmin && data.user.id!==state.user.id ){
          Vue.set( state.workingUser,response.result);
        }else{
          Vue.set( state.user,response.result);
        }
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
        // throw e.response
      }
      return response;
    },
    async uploadDocument({ dispatch}, data) {
      let response;
      let state=this.state;
      try{
        let formData = new FormData();
        formData.append('file', data.document.file);
        formData.append('type', data.document.type);
        formData.append('userId', data.user.id);
        response = (await axios.post(apiBaseURL+'/user/document', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })).data;
        console.log(response);
        if (state.isAdmin && data.user.id!==state.user.id ){
          Vue.set( state.workingUser, 'documents',response.result);
        }else{
          Vue.set( state.user, 'documents',response.result);
        }
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async deleteDocument({/*commit,*/ dispatch}, data) {
      let response;
      let state=this.state;
      try{
        response = (await axios.delete(apiBaseURL+"/user/document/"+data.document.id+"/"+data.user.id,{userId:data.user.id})).data;
        // this null inside try
        if (state.isAdmin && data.user.id!==state.user.id ){
          state.workingUser.documents=response.result;
          state.workingUser.validationMessages=response.validationMessages;
        }else{
          state.user.documents=response.result;
          state.user.validationMessages=response.validationMessages;
        }
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    // not used anymore
    async uploadPhotoServiceType({commit, dispatch}, data) {
      let response;
      try{
        let formData = new FormData();
        formData.append('file', data.file);
        formData.append('serviceId', data.service.id);
        if (data.description!==undefined){
          formData.append('description', data.description);
        }
        response = (await axios.post(apiBaseURL+'/user/service/photo', formData,{
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })).data;
        console.log(response);
        commit('setUser', response.result);
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
      }
      return response;
    },
      async uploadPhoto({/*commit,*/ dispatch}, data) {
          let response;
          let state= this.state;
          try{
              let formData = new FormData();
              formData.append('file', data.photo.file);
              formData.append('serviceTypeName', data.photo.serviceType.name);
              formData.append('userId', data.user.id);
              if (data.photo.description!==undefined){
                  formData.append('description', data.photo.description);
              }
              response = (await axios.post(apiBaseURL+'/user/photo', formData,{
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              })).data;
              console.log(response);
            if (state.isAdmin && data.user.id!==state.user.id ){
              state.workingUser=response.result;
              state.workingUser.validationMessages=response.validationMessages;
            }else{
              state.user=response.result;
              state.user.validationMessages=response.validationMessages;
            }
              // commit('setUser', response.result);
          }catch (e) {
              console.log(e.response)
              dispatch('handleResponse',e.response);
          }
          return response;
      },
    async deletePhotoServiceType({commit, dispatch}, photo) {
      let response;
      try{
        response = (await axios.delete(apiBaseURL+'/user/service/photo/'+photo.service.id+"/"+photo.id)).data;
        commit('setUser', response.result);
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async deletePhoto({/*commit,*/ dispatch}, data) {
      let response;
      let state=this.state;
      try{
        response = (await axios.delete(apiBaseURL+'/user/photo/'+data.photo.id+"/"+data.user.id,)).data;
        // commit('setUser', response.result);
        if (state.isAdmin && data.user.id!==state.user.id ){
          state.workingUser=response.result;
          state.workingUser.validationMessages=response.validationMessages;
        }else{
          state.user=response.result;
          state.user.validationMessages=response.validationMessages;
        }
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    // async getChefs(context) {
    //   const chefs = (await axios.get(apiURL+'/chefs')).data;
    //   context.commit('setChefs', chefs);
    // },
    // async createChef(context, names ) {
    //   const chef = (await axios.post(apiURL+'/chefs', names )).data;
    //   context.commit('addChef', chef);
    // },
    // async editChef(context, {id, names}) {
    //   const chef = (await axios.put('http://'+hostname+`/chefs/${id}`, names)).data;
    //   context.commit('editChef', chef);
    // },
    signup(context, params) {
      console.log("signup");
      let endpoint="/signup/client";
      if (params.iamachef==true){
        endpoint="/signup/chef";
      }
      return new Promise((resolve, reject) => {
        params.user.userAgent= navigator.userAgent;
        axios.post(apiBaseURL + endpoint, params.user)
            .then(response => {
                resolve(response);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
    login({dispatch}, credentials) {
      // let _this=this;
       return new Promise((resolve, reject) => {
         let bodyFormData = new FormData();
         bodyFormData.set('email', credentials.email);
         bodyFormData.set('password', credentials.password);
         bodyFormData.set('remember-me', true);
         // console.log(credentials);
             axios.post(apiBaseURL + '/login', bodyFormData)
             .then(/*response*/ () => {
               // console.log(response);
               dispatch('loggedin').then(response => {
                 resolve(response);
               }).catch(error => {
                 // console.log(error);
                 // console.log(error.response.data);
                 reject(error.response.data);
               })
             })
             .catch(error => {
               // console.log(error);
               // console.log(error.response.data);
               reject(error.response.data);
             })
       });
    },
    async loggedin() {
      let state=this.state;
      // let that=this;
      await axios.get(apiBaseURL + '/authenticated/', {withCredentials: true})
          .then(response => {
            // console.log(response);
            if (response.data.result===undefined){
              state.loggedin=false;
              state.isChef=false;
              state.isAdmin=false;
              delete(this.user);
            }else{
              state.user=response.data.result;
              state.user.validationMessages=response.data.validationMessages;
              if (response.data.result.type===1){
                state.isChef=true;
              }
              if (response.data.result.type===0){
                state.isAdmin=true;
              }
              state.loggedin=true;
              // that.checkActionToDo();
            }

          })
          .catch(/*error*/ () => {
            state.loggedin=false;
            state.isChef=false;
            state.isAdmin=false;
            delete(this.user);
            // console.log(error.response.data);
          })
      return state.loggedin;
    },
    sendForgotEmail(context, email) {
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/email/forgot/'+email )
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
    sendConfirmationEmail(context, email) {
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/email/confirmation/'+email )
            .then(response => {
              // console.log(response);
              resolve(response);
            })
            .catch(error => {
              // console.log(error.response.data);
              reject(error.response.data);
            })
      });
    },
    verifyEmail(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/email/confirm/'+data.email+'/'+data.code )
            .then(response => {
              // console.log(response);
              resolve(response);
            })
            .catch(error => {
              // console.log(error.response.data);
              reject(error.response.data);
            })
      });
    },
    confirmEmail(context, hash) {
      return new Promise((resolve, reject) => {
        axios.get(apiBaseURL + '/email/confirm/'+hash )
            .then(response => {
              // console.log(response);
              resolve(response);
            })
            .catch(error => {
              // console.log(error.response.data);
              reject(error.response.data);
            })
      });
    },
    changePassword(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/change_password',data )
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
    resetPassword(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/reset_password',data )
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
    async logout({dispatch}) {
      // const logout =
          (await axios.get(apiBaseURL+'/logout')).data;
      (await dispatch('loggedin'));
    },
    async users() {
      const users = (await axios.get(apiBaseURL+'/users')).data;
      console.log(users);
    },
    user() {
      const user = (axios.get(apiBaseURL+'/user')).data.result;
      console.log(user);
    },
    async getGeolocationAddress(context,userDetails){
      const params = new URLSearchParams({
        "q":(userDetails.city!==undefined?","+userDetails.city:"")+(userDetails.postcode!==undefined?","+userDetails.postcode:"")+(userDetails.country!==undefined?","+userDetails.country:""),
        "format":"json",
        "addressdetails":"1",
        "hierarchy":"1",
        "group_hierarchy":"1",
        "pretty":"1",
      }).toString();
      const places = (await axios.get('https://nominatim.openstreetmap.org/search?'+params ,  { withCredentials: false /*,params: {
          "q":city,
          "format":"json",
          "addressdetails":"1",
          "hierarchy":"1",
          "group_hierarchy":"1",
          "pretty":"1",
        }*/})).data;
      console.log(places);
      if (places!==undefined && places.length>0){
        userDetails.location={ "type": "Point", "coordinates": [ places[0].lat, places[0].lon ] };
        console.log(userDetails.location);
      }
      return userDetails;
    },
    async getGeolocationFullAddress(context,address){
      console.log(address);
      const params = new URLSearchParams({
        "q":address,
        "format":"json",
        "addressdetails":"1",
        "hierarchy":"1",
        "group_hierarchy":"1",
        "pretty":"1",
      }).toString();
      const places = (await axios.get('https://nominatim.openstreetmap.org/search?'+params ,  { withCredentials: false })).data;
      console.log(places);
      let location;
      if (places!==undefined && places.length>0){
        location={ "type": "Point", "coordinates": [ places[0].lat, places[0].lon ] };
        console.log(location);
      }
      return location;
    },
    async sendEmail({ dispatch}, email) {
      let response;
      try{
        let formData = new FormData();
        formData.append('sender', email.sender);
        formData.append('message', email.message);
        formData.append('subject', email.subject.displayName);
        response = (await axios.post(apiBaseURL+'/util/email', formData,{
        })).data;
        console.log(response);
      }catch (e) {
        console.log(e.response)
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async getUser(context , id) {
      let response
      try{
        response = await axios.get(apiBaseURL + '/admin/user/'+id, {withCredentials: true});
        console.log(response);

        this.state.workingUser=response.data.result;
        this.state.workingUser.validationMessages=response.data.validationMessages;
        // console.log(this.state.user);
        // if (this.$store.state.workingUser.id===this.user.id) {
        //   this.state.self=true;
        // }else{
        //   this.state.self=false;
        // }
      }catch(e) {
        // this.state.self=false;
        delete (this.state.workingUser);
      }
      console.log("retuning workingUser: "+ this.state.workingUser);
      return this.state.workingUser;
      // await axios.get(apiBaseURL + '/admin/user/'+id, {withCredentials: true})
      //     .then(response => {
      //       console.log(response);
      //       this.state.workingUser=response.data.result;
      //       this.state.workingUser.validationMessages=response.data.validationMessages;
      //     })
      //     .catch(/*error*/ () => {
      //       delete(this.state.workingUser);
      //     })
      // return this.state.workingUser;
    },
    async getChefsAdmin({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/admin/chefs', {withCredentials: true,params:data})).data
        this.state.chefsAdmin=response;
        console.log(response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return this.state.chefsAdmin;
    },
    async getChefsAdminStatusPending({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/admin/chefs/pending', {withCredentials: true,params:data})).data
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async getBookingsAdminPaymentStatus({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/admin/bookings/'+data, {withCredentials: true})).data
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async getClientsAdmin({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/admin/clients', {withCredentials: true,params:data})).data
        this.state.clientsAdmin=response;
        console.log(response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return this.state.clientsAdmin;
    },
    async asyncGetBookingsAdmin({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/booking/bookings', {withCredentials: true,params:data})).data
        this.state.bookingsAdmin=response;
        console.log(response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return this.state.bookingsAdmin;
    },
    async asyncGetBookingAdmin({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/booking/load', {withCredentials: true,params:data})).data;
        console.log(response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async asyncDeleteBooking({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/booking/delete', {withCredentials: true,params:data})).data;
        console.log(response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    getChef(context,data){
      return new Promise((resolve, reject) => {
        axios.get(apiBaseURL + '/search/user',{withCredentials: true,params:data})
            .then(response => {
              resolve(response.data.result);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
    getEvents(context,data){
      return new Promise((resolve, reject) => {
        axios.get(apiBaseURL + '/search/events',{withCredentials: true,params:data})
            .then(response => {
              resolve(response.data.result);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
    async asyncGetChef({dispatch},data) {
      let response;
      try{
        response= (await axios.get(apiBaseURL + '/search/user', {withCredentials: true,params:data})).data;
        console.log(response);
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    searchChefs(context,data) {
      return new Promise((resolve, reject) => {
        axios.get(apiBaseURL + '/search/chefs', {withCredentials: true, params: data})
            .then(response => {
              console.log(response);
              this.state.chefsSearch = response.data.result;
              if (response.data.infoMessages !== undefined) {
                this.state.infoMessages = response.data.infoMessages;
              } else {
                this.state.infoMessages = undefined;
              }
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
      searchChefsold(context,data) {
        axios.get(apiBaseURL + '/search/chefs', {withCredentials: true,params:data})
            .then(response => {
              console.log(response);
              this.state.chefsSearch=response.data.result;
              if (response.data.infoMessages!==undefined){
                this.state.infoMessages=response.data.infoMessages;
              }else{
                this.state.infoMessages=undefined;
              }

            })
            .catch(/*error*/ () => {

            })
        return this.state.chefsSearch;
    },
    async deleteUser({ dispatch},user) {
      let response;
      try{
        response= (await axios.delete(apiBaseURL + '/admin/user/'+user.id )).data
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    // async saveAvailability({dispatch}, data){
    //   let state=this.state;
    //   try{
    //     const response = (await axios.put(apiBaseURL+'/availability',data)).data;
    //     Vue.set(state,data.name,response);
    //   }catch (e) {
    //     dispatch('handleResponse',e.response);
    //   }
    // },
    async lookupAddress({ dispatch},data) {
      let response;
      try{
        response=(await axios.get(apiBaseURL + '/search/address', {withCredentials: true,params:data})).data;
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async lookupAddressMapbox({ dispatch},data) {
      const params = {
        "access_token":"pk.eyJ1IjoidG90b21hemUiLCJhIjoiY2tpaGRmdjQ3MDJ1dTJ3bW8ybGppbDhxYSJ9.QkbxBKUxKPKWGaa18zF_vQ",
        "autocomplete":"true",
        "country":"au",
        "proximity":"ip",
        "types":"address",
      };
      let response;
      try{
        response=(await axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/'+data+'.json?', {withCredentials: false,params:params})).data.features;
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    saveBooking(context, checkoutRequest) {
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/booking/save/',checkoutRequest )
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });

      // let response;
      // try{
      //   response=(await axios.post(apiBaseURL + '/booking/save/',checkoutRequest )).data;
      // }catch (e) {
      //   dispatch('handleResponse',e.response);
      // }
      // return response;
    },
    async modifyBooking({ dispatch}, data) {
      let response;
      try{
        response=(await axios.post(apiBaseURL + '/booking/modify/',data )).data;
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    acceptBooking({ dispatch}, data) {
      let state=this.state;
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/booking/confirm/',{reference:data} )
          .then(response => {
            console.log("confirmed");
            console.log(response);
            console.log(state);
            if (state.isAdmin){
              state.workingUser=response.data.result.chef;
            }
            if ( response.data.result.chef.id===state.user.id){
              state.user=response.data.result.chef;
            }
            resolve(response);
          })
          .catch(error => {
            dispatch('handleResponse',error.response);
            reject(error.response.data);
          })
      });
    },
    rejectBooking({ dispatch}, data) {
      let state=this.state;
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/booking/reject/',data )
            .then(response => {
              if (state.isAdmin){
                state.workingUser=response.data.result.chef;
              }
              if ( response.data.result.chef.id===state.user.id){
                state.user=response.data.result.chef;
              }
              resolve(response);
            })
            .catch(error => {
              dispatch('handleResponse',error.response);
              reject(error.response.data);
            })
      });
    },
    cancelBooking({ dispatch}, data) {
      let state=this.state;
      return new Promise((resolve, reject) => {
        axios.post(apiBaseURL + '/booking/cancel/',data )
            .then(response => {
              if (state.isAdmin){
                state.workingUser=response.data.result.chef;
              }
              if ( response.data.result.chef.id===state.user.id){
                state.user=response.data.result.chef;
              }
              resolve(response);
            })
            .catch(error => {
              dispatch('handleResponse',error.response);
              reject(error.response.data);
            })
      });
    },
    async searchAddress({dispatch}, data) {
      let response;
      try{
        response=(await axios.get(apiBaseURL + '/search/address/', {withCredentials: true,params:data})).data;
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async payment({dispatch}, data) {
      let response;
      try{
        response=(await axios.get(apiBaseURL + '/booking/payment/', {withCredentials: true,params:data})).data;
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async checkPayment({dispatch}, data) {
      let response;
      try{
        response=(await axios.post(apiBaseURL + '/stripe/payment/', data)).data;
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async waitingList({dispatch}, data) {
      let response;
      try{
        response=(await axios.post(apiBaseURL + '/user/waiting_list', data)).data;
      }catch (e) {
        dispatch('handleResponse',e.response);
      }
      return response;
    },
    async getPotentialCustomers({dispatch},data) {
      let response;
      try {
        response = (await axios.get(apiBaseURL + '/admin/potential_customers',{params:data})).data;
        this.state.potentialCustomers = response;
      } catch (e) {
        dispatch('handleResponse',e.response);
      }
      return this.state.potentialCustomers;
    },
    profile(context,data) {
      return new Promise((resolve, reject) => {
        axios.get(apiBaseURL + '/search/profile/'+data.name, {withCredentials: true, params: data})
            .then(response => {
              console.log(response);
              resolve(response.data);
            })
            .catch(error => {
              reject(error.response.data);
            })
      });
    },
    async startScheduledTask({ dispatch }) {
      try {
        await axios.get(apiBaseURL + '/util/scheduled');
      } catch (error) {
        dispatch('handleResponse', error.response);
      }
    },
    //https://api.mapbox.com/geocoding/v5/mapbox.places/5%20impasse%20de%20la%20chapelle%20saint%20leger.json?access_token=pk.eyJ1Ijoic2VhcmNoLW1hY2hpbmUtdXNlci0xIiwiYSI6ImNrN2Y1Nmp4YjB3aG4zZ253YnJoY21kbzkifQ.JM5ZeqwEEm-Tonrk5wOOMw&cachebuster=1607514361852&autocomplete=true&country=fr&types=address
  },
    modules: {}
});
