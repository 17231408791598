<template>
    <v-app>
        <navigation :show-drawer="$router.currentRoute.path.includes('/dashboard') || $router.currentRoute.path.includes('/admin') || $router.currentRoute.path.includes('/account')" :drawer="false"></navigation>
        <snackbar style="white-space: break-spaces;"/>
        <login :show="$root.showLogin" :close-action="closeLogin" ></login>
        <change-password :show="$root.showChangePWD" :close-action="closeChangePassword" ></change-password>
        <signup :chef="$root.showSignupChef" :show="$root.showSignup" :close-action="closeSignup"></signup>
        <v-main>
            <router-view/>
        </v-main>
        <v-dialog v-if="openContactUs" v-model="openContactUs" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Contact Us </span>
                </v-card-title>
                <v-form
                        ref="formContactUs"
                        v-model="valid"
                        @submit.prevent="sendMessage()"
                >
                    <v-card-text>
                        <v-container>
                            <v-text-field
                                    label="Your Email Address"
                                    v-model="email.sender"
                                    :rules="[v => !!v  || 'This field is required!']"
                            ></v-text-field>
                            <v-select v-if="this.$store.state.subjects"
                                    v-model="email.subject"
                                    :items="this.$store.state.subjects"
                                    item-text="displayName"
                                    :return-object=true
                                    label="Subject"
                                    :rules="[v => !!v && v.length !== 0 || 'You must select a Subject!']"
                                    required
                            ></v-select>
                            <v-textarea
                                    v-model="email.message"
                                    label="Message"
                                    hint="Message"
                                    placeholder="Message"
                                    :rules="[v => !!v  || 'This field is required!']"
                            ></v-textarea>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="openContactUs=false" >Close</v-btn>
                        <v-btn color="primary" :disabled="!valid" type="submit" :loading="isLoading">Send</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-footer v-if="!$router.currentRoute.path.includes('/dashboard') && !$router.currentRoute.path.includes('/admin') && !$router.currentRoute.path.includes('/account') " class="margin-top-big">
          <v-container fuild class="container margin " >
            <v-row >
            <v-btn icon href="https://www.facebook.com/Kootto-104830377944163" target="_blank" aria-label="Facebook link" style="align-self: center;">
                <v-icon aria-label="Facebook">mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon href="https://www.instagram.com/kootto_privatechef/" target="_blank" aria-label="Instagram link" style="align-self: center;">
                <v-icon aria-label="Instagram">mdi-instagram</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
                <a class="margin-default text-decoration-none" href="/terms" @click.prevent="$router.push({path: '/terms'})">Terms</a>
                <a class="margin-default text-decoration-none" href="/privacy" @click.prevent="$router.push({path: '/privacy'})">Privacy</a>
                <a class="margin-default text-decoration-none" href="/help" @click.prevent="$router.push({path: '/help'})">Help</a>
                <a class="margin-default text-decoration-none" href="/" @click.prevent="openContactUs=true">Contact Us</a>
            <v-spacer></v-spacer>
            <div style="font-size: x-small;align-self: center; " >v{{appVersion}} - Kootto © {{ new Date().getFullYear() }} {{env==="prod"?"":env}}</div>
            </v-row>

            <v-row>
              <v-divider></v-divider>
            </v-row>
          </v-container>

          <v-container fuild class="container margin-top-bottom-big " >
            <v-row class="subtitle-2">
              <div class="primary--text margin-top-bottom" ><a class=" text-decoration-none" href="/signup" @click.prevent="$router.push({path: `/signup`})">Key Features for chefs</a></div>
            </v-row>
            <v-row class="subtitle-2">
              <div class="primary--text" >Enjoy our private chef service in all Australia</div>
            </v-row>
            <v-row>
              <v-col :key="n" class="col-12 col-sm-6 col-md-4 col-lg-3 " style="padding:2px" v-for="n in cities">
                <a class="caption pa-1 text-decoration-none" :href="'/private-chef/city/'+n" @click.prevent="$router.push({path: `/private-chef/city/`+n })">private chef in {{n}}</a>
              </v-col>

            </v-row>
          </v-container>
        </v-footer>
    </v-app>
</template>

<script>
    import Constants from './constants';
    import { mapMutations } from "vuex";
    const Login = () => import(/* webpackChunkName: "login" */ './components/Login');
    // import Login from './components/Login';
    const Signup = () => import(/* webpackChunkName: "signup" */ './components/Signup');
    // import Signup from './components/Signup';
    const ChangePassword = () => import(/* webpackChunkName: "pass" */ './components/ChangePassword');
    // import ChangePassword from './components/ChangePassword';
    // import Navigation from './components/Navigation';
    const Navigation = () => import(/* webpackChunkName: "nav" */ './components/Navigation');
    // import Snackbar from './components/Snackbar';
    const Snackbar = () => import(/* webpackChunkName: "snack" */ './components/Snackbar');
    // Vue.component('star-rating', StarRating);
    // Vue.component('about-chef', AboutChef);
    // Vue.component('navigation', Navigation);
    // Vue.component('snackbar', Snackbar);
    export default {
        name: "App",
        components: {
            // StarRating
          Login,Signup,ChangePassword,Navigation,Snackbar
        },
        data() {
            let path=this.$router.currentRoute;
            return {
              title: process.env.VUE_APP_TITLE,
              env: process.env.VUE_APP_ENV,
              path,
              openContactUs:false,
              isLoading:false,
              email:{},
              valid:false,
              cities:["Adelaide",
                "Brisbane",
                "Bundaberg",
                "Cairns",
                "Canberra",
                "Darwin",
                "Geelong",
                "Gladstone",
                "Gold Coast",
                "Hobart",
                "Mackay",
                "Maroochydore",
                "Melbourne",
                "Newcastle",
                "Perth",
                "Rockhampton",
                "Sydney",
                "Toowoomba",
                "Townsville",
                "Wollongong",]
            };
        },
        async created() {
          // console.log("app created");
            // this.store.state.chefs = (await axios.get('http://localhost:3000/students')).data;

            // await this.$store.dispatch('getLanguages');
            // await this.$store.dispatch('getDocumentTypes');
            // await this.$store.dispatch('getServiceTypes');
            // await this.$store.dispatch('getDishTypes');
            // await this.$store.dispatch('getDietaryTags');
            // await this.$store.dispatch('getStyleTags');
            // await this.$store.dispatch('getSubjects');

          // await this.$store.dispatch('loggedin');
          // await this.$store.dispatch('getEnums');
          // await this.$store.dispatch('getParameters');
          // console.log("app ready");
        },
      mounted() {
        // console.log("App");
        this.$store.dispatch('loggedin').then(() => {
          this.$root.checkTodoAction();
        } );
        this.$store.dispatch('getEnums');
        this.$store.dispatch('getParameters');

        if (this.$route.query.campaign !== undefined || this.$route.query.cn !== undefined) {
          let campaign=this.$route.query.campaign;
          if (campaign === undefined){
            campaign=this.$route.query.cn;
          }

          if (localStorage){
            localStorage.setItem("campaign", campaign);
            let event={
              'event_category': "Campaign",
              'event_label': campaign
            }
            this.$gtag.event("Campaign", event);
          }
        }

        if (this.$route.query.confirm_email !== undefined) {
          let _this = this;
          // console.log(this.loggedin);
          // console.log(this.authenticated());
          this.$store.dispatch(
              'confirmEmail',
              this.$route.query.confirm_email
          ).then(function (response) {
            if (response.data.infoMessages !== undefined) {
              if (response.data.infoMessages['EMAIL_ALREADY_CONFIRMED'] !== undefined) {
                _this.showSnackbar({
                  text: response.data.infoMessages['EMAIL_ALREADY_CONFIRMED'] + " Please login",
                  color: "primary"
                });
              }
              if (response.data.infoMessages['EMAIL_CONFIRMED'] !== undefined) {
                _this.showSnackbar({text: response.data.infoMessages['EMAIL_CONFIRMED'] + " Please login", color: "primary"});
              }

            } else {
              _this.showSnackbar({text: Constants.EMAIL_CONFIRMED, color: "primary"});
            }
            _this.$root.$emit('showLogin', true);
          }).catch(function (value) {
                // show error
                this.log(value);
              }
          );
          this.log(this.$route.query.confirm_email);
          this.$emit('showLogin', true);
        }

        if (this.$route.query.reset_password !== undefined) {
          this.$root.showChangePWD=true;
          this.log(this.$route.query.reset_password);
        }
        // console.log(this.path);
      },
        methods: {
            ...mapMutations(["showSnackbar", "closeSnackbar"]),
            sendMessage(){
              this.isLoading=true;
                this.$store.dispatch('sendEmail', this.email)
                    .then((response) => {
                        if (response !== undefined && response.statusCode === 200) {
                            this.$store.dispatch('showSnackbar', {
                                text: Constants.SENT,
                                color: "primary",
                                timeout: 0
                            });
                            this.$refs.formContactUs.reset()
                          this.openContactUs=false;
                        }
                    }).finally(()=> {
                      this.isLoading=false;
                })
            },
            checkRoutes(){
                if (this.$store.state.isAdmin==true && !this.$router.currentRoute.path.includes('/dashboard') && !this.$router.currentRoute.path.includes('/admin')){
                    this.$router.push({path: `/admin/overview`});
                }else if (this.$store.state.isAdmin==false && (this.$router.currentRoute.path.includes('/admin') || this.$router.currentRoute.path.includes('/dashboard') )){
                    if (this.$store.state.isChef==true && !this.$router.currentRoute.path.includes('/dashboard') ){
                        this.$router.push({path: `/dashboard/overview`});
                    }else if (this.$store.state.isChef==false && (this.$router.currentRoute.path.includes('/dashboard') || this.$router.currentRoute.path.includes('/admin'))){
                        this.$router.push({path: `/`});
                    }
                }

            },
            closeLogin () {
              // console.log("closeMenu")
              this.$root.showLogin = false;
            },
            closeSignup () {
              this.$root.showSignup = false;
              this.$root.showSignupChef=false;
            },
          closeChangePassword () {
            // console.log("Close Change password")
            this.$root.showChangePWD = false;
          },
        },
        computed:{
          beforeRouteLeave (to, from, next) {
            // console.log("route leave");
            return next();
          }
        },

        watch: {
            '$store.state.isChef': function () {

              if (this.$route.query===undefined){
                this.checkRoutes();
              }

            },
            '$store.state.isAdmin': function () {
              // console.log(this.$route.query)
              if (this.$route.query===undefined){
                this.checkRoutes();
              }
            },

            $route (to, from){
                if (this.$store.state.loggedin==false && ( to.path.includes("/dashboard") || to.path.includes("/admin") || to.path.includes("/account")) ){
                  return this.$router.push({path: `/`})
                }

                if (to.path.includes("/contactUs")){
                  this.$router.push({path: from.path});
                  this.log("contact us");
                  this.openContactUs=true;
                  this.isLoading=true;
                  return
                }
                // console.log(to,from);
                // if ( to.path.includes("/book") && !to.path.includes("/account/bookings")){
                //   if (this.$root.typeServiceSelected!==null && this.$root.typeServiceSelected!==undefined){
                //     //this.$router.push(to);
                //   }
                // }
            }
        }
    };
</script>
