<template>
  <div class="home">
    <!--        <div class="overlay">-->
<!--    src="../assets/home_1280.webp"-->
<!--    lazy-src="../assets/home_400.webp"-->
<!--    :srcset="`${require('../assets/home_1280.webp')}, ${require('../assets/home_900.webp')} 900w, ${require('../assets/home_400.webp')} 400w`"-->
    <video-background
        class="theme--dark"
        :src="`${require('../assets/kootto.webm')}`"
        :poster="`${require('../assets/home_1280.webp')}`"
        style="max-height: 500px; height: 100vh;"
    >
      <div class="container">
        <v-col style="border-radius: 40px;background-color: #00000080;">
          <div class="row">
            <div class="col"></div>
          </div>
          <div class="row" >
            <div class=" white--text" style="visibility: visible;margin: 12px;text-align: center">
              <h1 style="font-size: 2em;font-weight: bold;">
                Invite your guests and our private chef takes care of the rest.
              </h1>
              <div class="text-h6">
                Kootto is a new way to dine, bringing top restaurant quality in
                the comfort of your home. Our service also takes the stress out
                of organizing your event in order to never miss a moment with the
                people that count.
              </div>
            </div>
          </div>
          <v-row align-content="center" justify="center">
            <v-btn href="/search" target="" @click.prevent="$router.push({path: `/search`})">See Menus</v-btn>
          </v-row>
          <div class="row">
            <div class="col"></div>
          </div>
        </v-col>
      </div>
    </video-background>
    <v-container fuild class="container margin-top-bottom-big">
      <v-row align-content="center" justify="space-around" class="margin-left-right-default" >
        <div dark>
          <h2 class="secondary-color-text">Bonus up to $200 for the first caterers to register *</h2>
          <br>
        </div>
      </v-row>

      <v-row align-content="center" justify="space-around" class="text-h5">
        <h2 class="mb-4 primary--text" style="font-weight: bold">How it works</h2>
      </v-row>
      <v-row align-content="center" justify="center">
        <v-col
          class="col-sm-3 col-12 text-center"
          align-content="center"
          justify="center"
        >
          <v-img
              src="../assets/icons/groceries.webp"
            height="100"
            contain
          ></v-img>
          <div>Our Chefs do the groceries</div>
        </v-col>
        <v-col
          class="col-sm-3 col-12 text-center"
          align-content="center"
          justify="center"
        >
          <v-img
              src="../assets/icons/cook.webp"
            height="100"
            contain
          ></v-img>
          <div>They cook in your kitchen</div>
        </v-col>
        <v-col
          class="col-sm-3 col-12 text-center"
          align-content="center"
          justify="center"
        >
          <v-img src="../assets/icons/serve.webp" height="100" contain></v-img>
          <div>They serve you</div>
        </v-col>
        <v-col
          class="col-sm-3 col-12 text-center"
          align-content="center"
          justify="center"
        >
          <v-img src="../assets/icons/clean.webp"
            height="100"
            contain
          ></v-img>
          <div>They cleanup before leaving</div>
        </v-col>
      </v-row>
    </v-container>

    <v-container fuild class="container margin-top-bottom-big ">
      <v-row align-content="center" justify="center">
        <v-col
                class="col-12 col-sm-6 text-center"
                align-content="center"
                justify="center"
        >
<!--          src="../assets/serving_900.webp"-->
<!--          lazy-src="../assets/serving_400.webp"-->
<!--          :srcset="`${require('../assets/serving_900.webp')}, ${require('../assets/serving_400.webp')} 500w`"-->
          <v-img
              src="../assets/serving_900.webp"
              :srcset="`${require('../assets/serving_900.webp')}, ${require('../assets/serving_400.webp')} 500w`"
              height="100%"
                  class="brighten-v-img joinus round-corner"
          >
            <div class="d-flex flex-column align-stretch" style="height: 100%">
<!--              <div class="align-start margin-default"></div>-->
<!--              <v-spacer></v-spacer>-->
              <div class="container text-h6" style="color:white">
                  <div class="margin-default" > Kootto is always looking for chefs to provide more choices for our customers.</div>
                  <div class="margin-default">If you are a passionate chef and love to be in contact with your clients, please join us</div>
              </div>
              <v-spacer></v-spacer>
              <div class="align-end container margin-top-bottom">
                <v-btn @click="click();$root.showSignup=true;$root.showSignupChef=true">
                  <span>Become a Chef</span>
                </v-btn>
              </div>
            </div>
          </v-img>
<!--          <div class="primary&#45;&#45;text text-h6">Join Us</div>-->
        </v-col>
<!--        <v-col-->
<!--                class=" col-12 col-sm-6 text-center"-->
<!--                align-content="center"-->
<!--                justify="center"-->
<!--        >-->
<!--          <div class="secondary-color-text text-h6">Brisbane, Gold Coast</div>-->
<!--          <v-img-->
<!--                  src="../assets/australiaMap.png"-->
<!--                  height="300"-->
<!--                  contain-->
<!--          >-->
<!--          </v-img>-->
<!--          <div class="text&#45;&#45;secondary">No Chefs near you, please signup, we will notify you when your region get covered</div>-->
<!--        </v-col>-->

      </v-row>
    </v-container>

    <v-container fuild class="container margin-top-bottom-big " >
      <v-row align-content="center" justify="space-around" class="text-h5">
        <h2 class="mb-4 primary--text" style="font-weight: bold">What we offer</h2>
      </v-row>
<!--      <v-row align-content="center" justify="space-between" class="text-shadow">-->
      <div class="gallery text-shadow" style="padding: 0;max-width: unset">
        <v-img
            src="../assets/course_250.webp"
                height="250px"
                max-width="250px"
                class="brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner"
        >
          <div class="d-flex flex-column align-stretch" style="height: 100%">
            <div class="container text-h4" style="color:white">
              <div class="margin-default dark-round-background" >Course Meal</div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-img>
        <v-img
            src="../assets/sharing_250.webp"
                height="250px"
                max-width="250px"
                class="brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner"
        >
          <div class="d-flex flex-column align-stretch" style="height: 100%">
            <div class="container text-h4" style="color:white">
              <div class="margin-default dark-round-background" >Sharing Platters</div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-img>

        <v-img
            src="../assets/cocktail_250.webp"
                height="250px"
                max-width="250px"
                class="brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner"
        >
          <div class="d-flex flex-column align-stretch" style="height: 100%">
            <div class="container text-h4" style="color:white">
              <div class="margin-default dark-round-background" > Cocktail food / Canapé </div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-img>
        <v-img
            src="../assets/grazing_250.webp"
                height="250px"
                max-width="250px"
                class="brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner"
        >
          <div class="d-flex flex-column align-stretch" style="height: 100%">
            <div class="container text-h4" style="color:white">
              <div class="margin-default" style="border-radius: 40px;background-color: #00000054;">Grazing Table / Buffet</div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-img>

        <v-img
            src="../assets/foodtruck_250.webp"
            height="250px"
            max-width="250px"
            class="brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner"
        >
          <div class="d-flex flex-column align-stretch" style="height: 100%">
            <div class="container text-h4" style="color:white">
              <div class="margin-default" style="border-radius: 40px;background-color: #00000054;">Food Truck/Street food</div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </v-img>

<!--      </v-row>-->
      </div>

    </v-container>

    <v-container>
    <v-row align-content="start" class="caption" >
      <div>(*) Please see the details on our <a class="text-decoration-none" href="/terms" @click.prevent="$router.push({path: `/terms`})">Terms and Conditions</a></div>
    </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";
  import {mapState} from "vuex";
  // import VideoBackground from 'vue-responsive-video-background-player'
  // import Vue from "vue";
  // Vue.component('video-background', VideoBackground);

  // import Signup from "@/components/Signup";
  // import Signup from '../components/Signup';

  export default {
    name: "Home",
    components: {
      // VideoBackground
      // Signup,
    },
    data() {
      let tomorrowDate=new Date();
      tomorrowDate.setDate(tomorrowDate.getDate()+1);
      return {
        tomorrow:tomorrowDate.toISOString() ,
        isLoading:false,
        lookupAddress:undefined,
        source:null,
        isLoadingPayment:false,
        valid:false,
      };
    },
    methods:{
      click(){
        console.log('clicked');
      },
      ...mapMutations(["showSnackbar", "closeSnackbar"]),
      openSnackbar() {
        this.showSnackbar({ text: "You are logged in!" ,color:"primary"})
      },
      resetDate(){
        this.$root.formData.date=undefined;
      },
      getLocation(val){
        console.log(val);
        console.log(this.geolocation);
        if (this.$root.formData.location!==undefined){
          this.$root.formData.geolocation=val.center;
        }else{
          this.$root.formData.geolocation=undefined;
        }
        return this.$root.formData.geolocation;
      }
    },
    computed: {
      ...mapState({
        loggedin: 'loggedin'
      }),
    },
    async created() {
     // this.$store.dispatch('loggedin');
    },
    mounted() {
      if (this.$route.query.verify_email!==undefined){
        let _this = this
        console.log(this.loggedin);
        // console.log(this.authenticated());
        this.$store.dispatch(
                'verifyEmail',
                this.$route.query.verify_email
        ).then(function (response) {
          if (response.data.infoMessages !== undefined ){
            if (response.data.infoMessages['EMAIL_ALREADY_VERIFIED']!==undefined){
              _this.showSnackbar({ text: response.data.infoMessages['EMAIL_ALREADY_VERIFIED'] +" Please login",color:"primary"});
            }
            if (response.data.infoMessages['EMAIL_VERIFIED']!==undefined){
              _this.showSnackbar({ text: response.data.infoMessages['EMAIL_VERIFIED'],color:"primary"});
            }

          }else{
            _this.showSnackbar({ text: "Your email has been verified ! Thank you." ,color:"primary"});
          }

        }).catch(function (value) {
                  // show error
                  console.log(value);
                }
        );
        console.log(this.$route.query.verify_email);
      }
      if (this.$route.query.reference!==undefined){
        this.$root.actionAfterLogin="LOAD_BOOKING";
        this.$root.bookingToLoad=this.$route.query.reference;
      }
    },
    watch: {
      lookupAddress (val) {
        if (val===undefined || val===null || val.length<4) {
          return
        }
        // Items have already been loaded
        // if (this.items !==undefined && this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        let that=this;
        // using mapbox
        this.$store.dispatch('lookupAddressMapbox',val).
        then(response => {
          that.$root.formData.locationItems=response; })
                .finally(() => (this.isLoading = false));
      },
    },
  }

</script>
